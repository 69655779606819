/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { CaseTeaser, Project, Stage } from '../components/molecules';
import { DefaultLayout } from '../components/layout';

const pageQuery = graphql`
  {
    allProjectsJson {
      edges {
        node {
          title
          category
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          caseUrl
        }
      }
    }
    allStagesJson(filter: { siteTitle: { eq: "Projekte" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
    allImageSharp(filter: { fluid: { originalName: { regex: "/example_nodes.png/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 800, quality: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

const Projects = ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={({ allStagesJson, allImageSharp, allProjectsJson }) => {
      const { siteTitle, siteDescription, imageSrc, imageAlt, title, contentBlocks } = allStagesJson.edges[0].node;
      const caseImage = allImageSharp.edges[0].node.fluid;

      return (
        <DefaultLayout siteTitle={siteTitle} siteDescription={siteDescription} location={location}>
          <Stage
            modifiers={['left-highlighted', 'gradient']}
            image={{
              fluid: imageSrc.childImageSharp.fluid,
              alt: imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: title }} />}
          >
            {contentBlocks.map(({ id, value }) => (
              <p key={id}>{value}</p>
            ))}
          </Stage>

          <CaseTeaser
            modifiers={['right-highlighted', 'image-padded']}
            url="https://deepconnect.deepreturn.com/"
            title="DeepConnect"
            subline="Case"
            image={{
              fluid: caseImage,
              alt: 'IDEALE LÖSUNG FÜR KMU',
            }}
            allProjects
          >
            <p>
              DeepConnect ist immer dann hilfreich, wenn Sie Formatierungen an einer CSV oder XML Datei vornehmen wollen.
              Mithilfe von DeepConnect können Sie selbständig Automatisierungen erstellen, ohne dazu auch nur eine Linie Code
              schreiben zu müssen.
              <br />
              <br />
              Die Software DeepConnect ermöglicht es Ihnen, Dateien automatisiert zu formatieren. Hierzu wird ein
              automatisierter Ablauf gebaut, wodurch CSV, sowie XML Dateien in die gewünschte Struktur gebracht werden
              können. Immer dann, wenn Sie an strukturierten Dateien mehrmals Formatierungen durchführen müssen, können Sie
              mit der Hilfe von DeepConnect eine enorme Zeitersparnis generieren. <br />
              <br />
              <br />
            </p>
          </CaseTeaser>
          <div className="project-list">
            <div className="container">
              <div className="row">
                {allProjectsJson.edges.map(({ node }) => (
                  <Project
                    key={node.title}
                    title={node.title}
                    category={node.category}
                    image={{
                      fluid: node.image.childImageSharp.fluid,
                      alt: node.title,
                    }}
                    caseUrl={node.caseUrl}
                  >
                    <p dangerouslySetInnerHTML={{ __html: node.description }} />
                  </Project>
                ))}
              </div>
            </div>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default Projects;
